.portfolioContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--text-color);
    background-color: var(--background-color);
  }
  
  .portfolioTitle {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .portfolioDescription {
    font-size: 1.1rem;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .portfolioItem {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--card-bg);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .portfolioItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .portfolioItem:hover .itemTitle {
    color: var(--theme-accent);
  }
  
  .itemsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .portfolioThumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  .portfolioImage {
    width: 100%;
    aspect-ratio: 16 / 9;  /* Ensures professional, consistent sizing */
    object-fit: cover;      /* Prevents distortion, crops neatly */
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .itemTitle {
    font-size: 1.5rem;
    margin: 0rem 0 1rem;
  }
  
  .portfolioDescription {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .itemLink {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: var(--primary-color);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .itemLink:hover {
    background-color: var(--accent-color);
    color: var(--background-color);
  }
  

  
  @media (max-width: 600px) {
    .portfolioTitle {
      font-size: 2rem;
    }
    .portfolioContainer {
      padding: 1rem;
    }
  }